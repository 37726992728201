<template>
  <section id="section-partner" class="bg-green-super-light-green">
    <div class="nstra-container">
      <div v-scrollanimation="'left'">
        <b-row>
          <b-col cols="12" md="6" v-scrollanimation="'left'" class="mb-30px mb-lg-0px">
            <img class="partner-logo partner-logo-gojek" src="@/assets/images/logo-partner-gojek.png" alt="gojek" />
            <h3 class="partner-title bold">Collaborating with Go-Jek</h3>
            <p class="partner-text">
              At the growth of the startup market in Indonesia, we seized the opportunity as an early collaborator with Go-jek, an online ondemand transportation platform start-up, as it launched its
              digital platform to become Indonesia’s first decacorn.
            </p>
          </b-col>
          <b-col cols="12" md="6" v-scrollanimation="'left'">
            <img class="partner-logo partner-logo-sayurbox" src="@/assets/images/logo-partner-sayurbox.png" alt="sayurbox" />
            <h3 class="partner-title bold">Becoming an incubator in Sayurbox</h3>
            <p class="partner-text">
              Following the successful collaboration with Go-Jek,  we continued as incubator for one of Indonesia’s fastest growing start-ups, Sayurbox, an online-based agricultural produce distribution platform.
            </p>
          </b-col>
        </b-row>
      </div>
    </div>
  </section>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol
  },
};
</script>
